<template>
  <b-card title="Echo" sub-title="Information about echo">
    <b-row>
      <b-col md="3">
        <b-row>
          <b-col cols="12">
            <b-form-group label="LVEF" label-for="lvef" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.postop_echo_LVEF"
                id="lvef"
                type="number"
                placeholder="LVEF"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="LVEDD" label-for="lvedd" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.postop_echo_LVEDD"
                id="lvedd"
                type="number"
                placeholder="LVEDD"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="RVEDD" label-for="rvedd" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.postop_echo_RVEDD"
                id="rvedd"
                type="number"
                placeholder="RVEDD"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="FAC RV" label-for="fac_rv" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.postop_echo_FAC_RV"
                id="fac_rv"
                type="number"
                placeholder="FAC RV"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="FAC LV" label-for="fac_lv" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.postop_echo_FAC_LV"
                id="fac_lv"
                type="number"
                placeholder="FAC LV"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="LVESVI" label-for="lvesvi" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.postop_echo_LVESVI"
                id="lvesvi"
                type="number"
                placeholder="LVESVI"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="TEI index"
              label-for="tei_index"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.postop_echo_TEI_index"
                id="tei_index"
                type="number"
                placeholder="TEI index"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="pHTN > 60mmHg"
              label-for="pHTN"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_echo_phtn_over_60mmhg"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="LVESD [mm]"
              label-for="lvesd"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.postop_echo_lvesd"
                id="lvesd"
                type="number"
                placeholder="LVESD"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="LVEDV [mm]"
              label-for="lvedv"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.postop_echo_lvedv"
                id="lvedv"
                type="number"
                placeholder="LVEDV [mm]"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="LVESV [mm]"
              label-for="lvesv"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.postop_echo_lvesv"
                id="lvesv"
                type="number"
                placeholder="LVESV [mm]"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="RVEDD Basal [mm]"
              label-for="rvedd_basal"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.postop_echo_rvedd_basal"
                id="rvedd_basal"
                type="number"
                placeholder="RVEDD Basal [mm]"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="RVEDD Mid Cavity [mm]"
              label-for="rvedd_mid_cavity"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.postop_echo_rvedd_mid_cavity"
                id="rvedd_mid_cavity"
                type="number"
                placeholder="RVEDD Mid Cavity"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="RV GLS [%]"
              label-for="rv_gls"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.postop_echo_rv_gls"
                id="rv_gls"
                type="number"
                placeholder="RV GLS [%]"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="RV EF Grade [%]"
              label-for="rvef_grade"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.postop_echo_rvef_grade"
                id="rvef_grade"
                type="number"
                placeholder="RV EF Grade [%]"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Left Atrium Strain [%]"
              label-for="left_atrium_strain"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.postop_echo_left_atrium_strain"
                id="left_atrium_strain"
                type="number"
                placeholder="Left Atrium Strain [%]"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="LAVI [ml/m^2]"
              label-for="lavi"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.postop_echo_lavi"
                id="lavi"
                type="number"
                placeholder="LAVI [ml/m^2]"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-card class="bordered-card" title="AV">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="AV aortic insuff"
                    label-for="av"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.postop_echo_av_preop_aortic_insuff"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Grade"
                    label-for="grade"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.postop_echo_av_preop_arotic_insuff_grade
                      "
                      id="grade"
                      type="number"
                      placeholder="Grade"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="3">
        <b-card class="bordered-card" title="MV">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="MR grade"
                label-for="mr_grade"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.postop_echo_mv_MR_grade"
                  id="mr_grade"
                  type="number"
                  placeholder="MR grade"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MV diameter"
                label-for="mv_diameter"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.postop_echo_mv_MV_diameter"
                  id="mv_diameter"
                  type="number"
                  placeholder="MV diameter"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR Pmax"
                label-for="mr_pmax"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.postop_echo_mv_MR_pmax"
                  id="mr_pmax"
                  type="number"
                  placeholder="MR Pmax"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MV Pmax"
                label-for="mv_pmax"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.postop_echo_mv_MR_vmax"
                  id="mv_pmax"
                  type="number"
                  placeholder="MV Pmax"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR peak velocity[ms]"
                label-for="mr_velocity"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.postop_echo_mv_MR_peak_velocity_ms"
                  id="mr_velocity"
                  type="number"
                  placeholder="MR peak velocity[ms]"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR vena contracta"
                label-for="mr_contracta"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.postop_echo_mv_MR_vena_contracta"
                  id="mr_contracta"
                  type="number"
                  placeholder="MR vena contracta"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR EROA"
                label-for="mr_eroa"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.postop_echo_mv_MR_EROA"
                  id="mr_eroa"
                  type="number"
                  placeholder="MR EROA"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR PISA"
                label-for="mr_pisa"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.postop_echo_mv_MR_PISA"
                  id="mr_pisa"
                  type="number"
                  placeholder="MR PISA"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR meangradient"
                label-for="mr_meangradient"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.postop_echo_mv_MR_meangradient"
                  id="mr_meangradient"
                  type="number"
                  placeholder="MR meangradient"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="TDIS velocity MR annulus"
                label-for="tdis"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.postop_echo_mv_TDI_S_velocity_MR_annulus"
                  id="tdis"
                  type="number"
                  placeholder="TDIS velocity MR annulus"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MV MR Regurgitant Volume [ml]"
                label-for="mv_mr_regurgitant_volume"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.postop_echo_mv_mr_regurgitant_volume"
                  id="mv_mr_regurgitant_volume"
                  type="number"
                  placeholder="MV MR Regurgitant Volume [ml]"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Peak Gradient [mmHg]"
                label-for="mv_peak_gradient"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.postop_echo_mv_peak_gradient"
                  id="mv_peak_gradient"
                  type="number"
                  placeholder="Peak Gradient [mmHg]"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MV Area [cm^2]"
                label-for="mv_area"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.postop_echo_mv_area"
                  id="mv_area"
                  type="number"
                  placeholder="MV Area [cm^2]"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MV Mean Gradient [mmHg]"
                label-for="mv_mean_gradient"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.postop_echo_mv_mean_gradient"
                  id="mv_mean_gradient"
                  type="number"
                  placeholder="MV Mean Gradient [mmHg]"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card class="bordered-card" title="TV">
          <b-row>
            <b-col md="6">
              <b-col cols="12">
                <b-form-group
                  label="TR grade"
                  label-for="tr_grade"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_tv_TR_grade"
                    id="tr_grade"
                    type="number"
                    placeholder="TR grade"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="PA pressure systolic"
                  label-for="pa_pressure"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_tv_pa_pressure_systolic"
                    id="pa_pressure"
                    type="number"
                    placeholder="PA pressure systolic"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="VCL diameter[cm]"
                  label-for="vcl_d"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_tv_VCL_diameter_cm"
                    id="vcl_d"
                    type="number"
                    placeholder="VCL diameter[cm]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="TAPSE" label-for="tapse" label-cols-md="4">
                  <b-form-input
                    v-model="patientInfo.postop_echo_tv_TAPSE"
                    id="tapse"
                    type="number"
                    placeholder="TAPSE"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TR Pmax [mmHg]"
                  label-for="tr_pmax"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_tv_TR_pmax_mmhg"
                    id="tr_pmax"
                    type="number"
                    placeholder="TR Pmax [mmHg]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TR Vmax"
                  label-for="tr_vmax"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_tv_TR_vmax"
                    id="tr_vmax"
                    type="number"
                    placeholder="TR Vmax"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TR peak velocity"
                  label-for="tr_velocity"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_tv_TR_peak_velocity_ms"
                    id="tr_velocity"
                    type="number"
                    placeholder="TR peak velocity"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TDIS velocity TR annulus"
                  label-for="tdis"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="
                      patientInfo.postop_echo_tv_TDI_S_velocity_TR_annulus
                    "
                    id="tdis"
                    type="number"
                    placeholder="TDIS velocity TR annulus"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Stroke work index"
                  label-for="stroke_work"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_tv_stroke_work_index"
                    id="stroke_work"
                    type="number"
                    placeholder="Stroke work index"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tricusp annulus diameter"
                  label-for="tricusp"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="
                      patientInfo.postop_echo_tv_tricusp_annulus_diameter
                    "
                    id="tricusp"
                    type="number"
                    placeholder="Tricusp annulus diameter"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TV Etiology (Postoperative Echo)"
                  label-for="tv_etiology"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.postop_echo_tv_etiology"
                    label="value"
                    :options="optionsTVEtiology"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-col>
            <b-col md="6">
              <b-col cols="12">
                <b-form-group
                  label="TV TR EROA [mm^2]"
                  label-for="tv_tr_eroa"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_tv_tr_eroa"
                    id="tv_tr_eroa"
                    type="number"
                    placeholder="TV TR EROA [mm^2]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TV Coaptation Gap [mm]"
                  label-for="tv_coaptation_gap"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_tv_coaptation_gap"
                    id="tv_coaptation_gap"
                    type="number"
                    placeholder="TV Coaptation Gap [mm]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="TV Area [mm^2]"
                  label-for="tv_area"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_tv_area"
                    id="tv_area"
                    type="number"
                    placeholder="TV Area [mm^2]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TV Mean Gradient [mmHg]"
                  label-for="tv_mean_gradient"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_tv_mean_gradient"
                    id="tv_mean_gradient"
                    type="number"
                    placeholder="TV Mean Gradient [mmHg]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="TV Stenosis (Postoperative Echo)"
                  label-for="tv_stenosis"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.postop_echo_tv_stenosis"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TV RA Diameter [mm]"
                  label-for="tv_ra_diameter"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_tv_ra_diameter"
                    id="tv_ra_diameter"
                    type="number"
                    placeholder="TV RA Diameter [mm]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="TV RA Volume [mm^2]"
                  label-for="tv_ra_volume"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_tv_ra_volume"
                    id="tv_ra_volume"
                    type="number"
                    placeholder="TV RA Volume [mm^2]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="TV S-TDI"
                  label-for="tv_s_tdi"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.postop_echo_tv_s_tdi"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TV Mean Gradient [mmHg]"
                  label-for="tv_mean_gradient"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_tv_mean_gradient"
                    id="tv_mean_gradient"
                    type="number"
                    placeholder="TV Mean Gradient [mmHg]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="bordered-card" title="Svan Ganz">
          <b-row>
            <b-col md="6">
              <b-col cols="12">
                <b-form-group
                  label="CVP RA"
                  label-for="cvp_ra"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_svan_ganz_CVP_RA"
                    id="cvp_ra"
                    type="number"
                    placeholder="CVP RA"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="PCWP" label-for="pcwp" label-cols-md="4">
                  <b-form-input
                    v-model="patientInfo.postop_echo_svan_ganz_PCWP"
                    id="pcwp"
                    type="number"
                    placeholder="PCWP"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="PA systolic pressure"
                  label-for="pa_systolic"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_svan_ganz_PA_systolic_p"
                    id="pa_systolic"
                    type="number"
                    placeholder="PA systolic pressure"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="PA diastolic pressure"
                  label-for="pa_diastolic"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_svan_ganz_PA_diastolic_p"
                    id="pa_diastolic"
                    type="number"
                    placeholder="PA diastolic pressure"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="PA mean"
                  label-for="pa_mean"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_svan_ganz_PA_mean"
                    id="pa_mean"
                    type="number"
                    placeholder="PA mean"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-col>
            <b-col md="6">
              <b-col cols="12">
                <b-form-group label="SVR" label-for="svr" label-cols-md="4">
                  <b-form-input
                    v-model="patientInfo.postop_echo_svan_ganz_SVR"
                    id="svr"
                    type="number"
                    placeholder="SVR"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="PVR" label-for="pvr" label-cols-md="4">
                  <b-form-input
                    v-model="patientInfo.postop_echo_svan_ganz_PVR"
                    id="pvr"
                    type="number"
                    placeholder="PVR"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Stroke volume"
                  label-for="stroke_volume"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_svan_ganz_stroke_volume"
                    id="stroke_volume"
                    type="number"
                    placeholder="Stroke volume"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Heart rate"
                  label-for="heart_rate"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_svan_ganz_heart_rate"
                    id="heart_rate"
                    type="number"
                    placeholder="Heart rate"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Cardiac index"
                  label-for="cardiac_index"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.postop_echo_svan_ganz_cardiac_index"
                    id="cardiac_index"
                    type="number"
                    placeholder="Cardiac index"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        postop_echo_LVEF: 0,
        postop_echo_LVEDD: 0,
        postop_echo_RVEDD: 0,
        postop_echo_FAC_RV: 0,
        postop_echo_FAC_LV: 0,
        postop_echo_LVESVI: 0,
        postop_echo_TEI_index: 0,
        postop_echo_av_preop_aortic_insuff: "",
        postop_echo_av_preop_arotic_insuff_grade: 0,
        postop_echo_phtn_over_60mmhg: "",
        postop_echo_lvesd: "",
        postop_echo_lvedv: "",
        postop_echo_lvesv: "",
        postop_echo_rvedd_basal: "",
        postop_echo_rvedd_mid_cavity: "",
        postop_echo_rv_gls: "",
        postop_echo_rvef_grade: "",
        postop_echo_left_atrium_strain: "",
        postop_echo_lavi: "",

        postop_echo_tv_TR_grade: 0,
        postop_echo_tv_pa_pressure_systolic: 0,
        postop_echo_tv_VCL_diameter_cm: 0,
        postop_echo_tv_TAPSE: 0,
        postop_echo_tv_TR_pmax_mmhg: 0,
        postop_echo_tv_TR_vmax: 0,
        postop_echo_tv_TR_peak_velocity_ms: 0,
        postop_echo_tv_TDI_S_velocity_TR_annulus: 0,
        postop_echo_tv_stroke_work_index: 0,
        postop_echo_tv_tricusp_annulus_diameter: 0,
        postop_echo_tv_tr_eroa: "",
        postop_echo_tv_coaptation_gap: "",
        postop_echo_tv_area: "",
        postop_echo_tv_mean_gradient: "",

        postop_echo_mv_MR_grade: 0,
        postop_echo_mv_MV_diameter: 0,
        postop_echo_mv_MR_pmax: 0,
        postop_echo_mv_MR_vmax: 0,
        postop_echo_mv_MR_peak_velocity_ms: 0,
        postop_echo_mv_MR_vena_contracta: 0,
        postop_echo_mv_MR_EROA: 0,
        postop_echo_mv_MR_PISA: 0,
        postop_echo_mv_MR_meangradient: 0,
        postop_echo_mv_TDI_S_velocity_MR_annulus: 0,
        postop_echo_mv_mr_regurgitant_volume: "",
        postop_echo_mv_peak_gradient: "",
        postop_echo_mv_area: "",
        postop_echo_mv_mean_gradient: "",

        postop_echo_svan_ganz_CVP_RA: 0,
        postop_echo_svan_ganz_PCWP: 0,
        postop_echo_svan_ganz_PA_systolic_p: 0,
        postop_echo_svan_ganz_PA_diastolic_p: 0,
        postop_echo_svan_ganz_PA_mean: 0,
        postop_echo_svan_ganz_SVR: 0,
        postop_echo_svan_ganz_PVR: 0,
        postop_echo_svan_ganz_stroke_volume: 0,
        postop_echo_svan_ganz_heart_rate: 0,
        postop_echo_svan_ganz_cardiac_index: 0,
        postop_echo_tv_etiology: "",
        postop_echo_tv_stenosis: "",
        postop_echo_tv_ra_diameter: "",
        postop_echo_tv_ra_volume: "",

        postop_echo_tv_s_tdi: "",
        postop_echo_tv_mean_gradient: "",
      },
      options: ["yes", "no"],
      optionsTVEtiology: ["secondary", "atrial", "ventricular"],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    setPatientInfo(v) {
      this.patientInfo._id = v._id;
      this.patientInfo.postop_echo_LVEF = v.postop_echo_LVEF;
      this.patientInfo.postop_echo_LVEDD = v.postop_echo_LVEDD;
      this.patientInfo.postop_echo_RVEDD = v.postop_echo_RVEDD;
      this.patientInfo.postop_echo_FAC_RV = v.postop_echo_FAC_RV;
      this.patientInfo.postop_echo_FAC_LV = v.postop_echo_FAC_LV;
      this.patientInfo.postop_echo_LVESVI = v.postop_echo_LVESVI;
      this.patientInfo.postop_echo_TEI_index = v.postop_echo_TEI_index;
      this.patientInfo.postop_echo_av_preop_aortic_insuff =
        v.postop_echo_av_preop_aortic_insuff;
      this.patientInfo.postop_echo_av_preop_arotic_insuff_grade =
        v.postop_echo_av_preop_arotic_insuff_grade;
      this.patientInfo.postop_echo_phtn_over_60mmhg =
        v.postop_echo_phtn_over_60mmhg;
      this.patientInfo.postop_echo_lvesd = v.postop_echo_lvesd;
      this.patientInfo.postop_echo_lvedv = v.postop_echo_lvedv;
      this.patientInfo.postop_echo_lvesv = v.postop_echo_lvesv;
      this.patientInfo.postop_echo_rvedd_basal = v.postop_echo_rvedd_basal;
      this.patientInfo.postop_echo_rvedd_mid_cavity =
        v.postop_echo_rvedd_mid_cavity;
      this.patientInfo.postop_echo_rv_gls = v.postop_echo_rv_gls;
      this.patientInfo.postop_echo_rvef_grade = v.postop_echo_rvef_grade;
      this.patientInfo.postop_echo_left_atrium_strain =
        v.postop_echo_left_atrium_strain;
      this.patientInfo.postop_echo_lavi = v.postop_echo_lavi;

      this.patientInfo.postop_echo_tv_TR_grade = v.postop_echo_tv_TR_grade;
      this.patientInfo.postop_echo_tv_pa_pressure_systolic =
        v.postop_echo_tv_pa_pressure_systolic;
      this.patientInfo.postop_echo_tv_VCL_diameter_cm =
        v.postop_echo_tv_VCL_diameter_cm;
      this.patientInfo.postop_echo_tv_TAPSE = v.postop_echo_tv_TAPSE;
      this.patientInfo.postop_echo_tv_TR_pmax_mmhg =
        v.postop_echo_tv_TR_pmax_mmhg;
      this.patientInfo.postop_echo_tv_TR_vmax = v.postop_echo_tv_TR_vmax;
      this.patientInfo.postop_echo_tv_TR_peak_velocity_ms =
        v.postop_echo_tv_TR_peak_velocity_ms;
      this.patientInfo.postop_echo_tv_TDI_S_velocity_TR_annulus =
        v.postop_echo_tv_TDI_S_velocity_TR_annulus;
      this.patientInfo.postop_echo_tv_stroke_work_index =
        v.postop_echo_tv_stroke_work_index;
      this.patientInfo.postop_echo_tv_tricusp_annulus_diameter =
        v.postop_echo_tv_tricusp_annulus_diameter;
      this.patientInfo.postop_echo_tv_tr_eroa = v.postop_echo_tv_tr_eroa;
      this.patientInfo.postop_echo_tv_coaptation_gap =
        v.postop_echo_tv_coaptation_gap;
      this.patientInfo.postop_echo_tv_area = v.postop_echo_tv_area;
      this.patientInfo.postop_echo_tv_mean_gradient =
        v.postop_echo_tv_mean_gradient;

      this.patientInfo.postop_echo_mv_MR_grade = v.postop_echo_mv_MR_grade;
      this.patientInfo.postop_echo_mv_MV_diameter =
        v.postop_echo_mv_MV_diameter;
      this.patientInfo.postop_echo_mv_MR_pmax = v.postop_echo_mv_MR_pmax;
      this.patientInfo.postop_echo_mv_MR_vmax = v.postop_echo_mv_MR_vmax;
      this.patientInfo.postop_echo_mv_MR_peak_velocity_ms =
        v.postop_echo_mv_MR_peak_velocity_ms;
      this.patientInfo.postop_echo_mv_MR_vena_contracta =
        v.postop_echo_mv_MR_vena_contracta;
      this.patientInfo.postop_echo_mv_MR_EROA = v.postop_echo_mv_MR_EROA;
      this.patientInfo.postop_echo_mv_MR_PISA = v.postop_echo_mv_MR_PISA;
      this.patientInfo.postop_echo_mv_MR_meangradient =
        v.postop_echo_mv_MR_meangradient;
      this.patientInfo.postop_echo_mv_TDI_S_velocity_MR_annulus =
        v.postop_echo_mv_TDI_S_velocity_MR_annulus;
      this.patientInfo.postop_echo_mv_mr_regurgitant_volume =
        v.postop_echo_mv_mr_regurgitant_volume;
      this.patientInfo.postop_echo_mv_peak_gradient =
        v.postop_echo_mv_peak_gradient;
      this.patientInfo.postop_echo_mv_area = v.postop_echo_mv_area;
      this.patientInfo.postop_echo_mv_mean_gradient =
        v.postop_echo_mv_mean_gradient;

      this.patientInfo.postop_echo_svan_ganz_CVP_RA =
        v.postop_echo_svan_ganz_CVP_RA;
      this.patientInfo.postop_echo_svan_ganz_PCWP =
        v.postop_echo_svan_ganz_PCWP;
      this.patientInfo.postop_echo_svan_ganz_PA_systolic_p =
        v.postop_echo_svan_ganz_PA_systolic_p;
      this.patientInfo.postop_echo_svan_ganz_PA_diastolic_p =
        v.postop_echo_svan_ganz_PA_diastolic_p;
      this.patientInfo.postop_echo_svan_ganz_PA_mean =
        v.postop_echo_svan_ganz_PA_mean;
      this.patientInfo.postop_echo_svan_ganz_SVR = v.postop_echo_svan_ganz_SVR;
      this.patientInfo.postop_echo_svan_ganz_PVR = v.postop_echo_svan_ganz_PVR;
      this.patientInfo.postop_echo_svan_ganz_stroke_volume =
        v.postop_echo_svan_ganz_stroke_volume;
      this.patientInfo.postop_echo_svan_ganz_heart_rate =
        v.postop_echo_svan_ganz_heart_rate;
      this.patientInfo.postop_echo_svan_ganz_cardiac_index =
        v.postop_echo_svan_ganz_cardiac_index;
      this.patientInfo.postop_echo_tv_etiology = v.postop_echo_tv_etiology;
      this.patientInfo.postop_echo_tv_stenosis = v.postop_echo_tv_stenosis;
      this.patientInfo.postop_echo_tv_ra_diameter =
        v.postop_echo_tv_ra_diameter;
      this.patientInfo.postop_echo_tv_ra_volume = v.postop_echo_tv_ra_volume;

      this.patientInfo.postop_echo_tv_s_tdi = v.postop_echo_tv_s_tdi;
      this.patientInfo.postop_echo_tv_mean_gradient =
        v.postop_echo_tv_mean_gradient;
    },
  },
};
</script>

<style>
</style>
