<template>
  <b-card title="Complications" sub-title="Information about Complications">
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Hemothorax"
              label-for="postop_complications_hemothorax"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_hemothorax"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Pericardial Tamponade"
              label-for="postop_complications_pericardial_tamponade"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_pericardial_tamponade"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Rethoracotomy"
              label-for="postop_complications_rethoracotomy"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_rethoracotomy"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Rethoracotomy Reason"
              label-for="postop_complications_rethoracotomy_reason"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_rethoracotomy_reason"
                label="value"
                :options="options1"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="AKIN renal failure"
              label-for="postop_complications_AKIN_renal_failure"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_AKIN_renal_failure"
                label="value"
                :options="options2"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Delir"
              label-for="postop_complications_delir"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_delir"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="New onset afib"
              label-for="postop_complications_new_onset_afib"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_new_onset_afib"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="New conduction disorder"
              label-for="postop_complications_new_conduction_disorder"
              label-cols-md="4"
            >
              <v-select
                v-model="
                  patientInfo.postop_complications_new_conduction_disorder
                "
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Rhythm TAA"
              label-for="postop_complications_rhythm_TAA"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_rhythm_TAA"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Reintervention Needed"
              label-for="reintervention"
              label-cols-md="4"
            >
              <v-select
                v-model="
                  patientInfo.postop_complications_cardiac_surgery_reintervention
                "
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Cardiac Surgery Reintervention Type"
              label-for="postop_complications_cardiac_surgery_reintervention_type"
              label-cols-md="4"
            >
              <b-form-input
                id="postop_complications_cardiac_surgery_reintervention_type"
                v-model="
                  patientInfo.postop_complications_cardiac_surgery_reintervention_type
                "
                placeholder="Cardiac Surgery Reintervention Type"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Systolic Anterior Motion (SAM) Complication"
              label-for="sam_complication"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_SAM"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="SAM Treatment"
              label-for="postop_complications_SAM_treatment"
              label-cols-md="4"
            >
              <b-form-input
                id="postop_complications_SAM_treatment"
                v-model="patientInfo.postop_complications_SAM_treatment"
                placeholder="SAM Treatment"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Low Cardiac Output Syndrome (LCOS) Complication"
              label-for="lcots_complication"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_LCOS"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="LCOS Treatment"
              label-for="postop_complications_LCOS_treatment"
              label-cols-md="4"
            >
              <b-form-input
                id="postop_complications_LCOS_treatment"
                v-model="patientInfo.postop_complications_LCOS_treatment"
                placeholder="LCOS Treatment"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Pacemaker"
              label-for="postop_complications_pacemaker"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_pacemaker"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="PostOP days to pacemaker [d]"
              label-for="postop_complications_pacemaker_postop_days_to_pacemaker"
              label-cols-md="4"
            >
              <b-form-input
                id="postop_complications_pacemaker_postop_days_to_pacemaker"
                v-model="
                  patientInfo.postop_complications_pacemaker_postop_days_to_pacemaker
                "
                placeholder=""
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="BARC bleeding < 30d"
              label-for="postop_complications_BARC_bleeding_30d"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_BARC_bleeding_30d"
                label="value"
                :options="options3"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Tracheotomy"
              label-for="postop_complications_tracheotomy"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_tracheotomy"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Pneumonia"
              label-for="postop_complications_pneumonia"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_pneumonia"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Sepsis"
              label-for="postop_complications_sepsis"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_sepsis"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Impaired wound healing"
              label-for="postop_complications_impaired_wound_healing"
              label-cols-md="4"
            >
              <v-select
                v-model="
                  patientInfo.postop_complications_impaired_wound_healing
                "
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Stroke"
              label-for="postop_complications_stroke"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_stroke"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Myocardial infarction"
              label-for="postop_complications_myocardial_infarction"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_complications_myocardial_infarction"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Partial Leaflets Detachment (TEER Complication)"
              label-for="leaflets_detachment"
              label-cols-md="4"
            >
              <v-select
                v-model="
                  patientInfo.postop_complications_TEER_partial_leaflets_detachment
                "
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Complete Leaflets Detachment (TEER Complication)"
              label-for="complete_leaflets_detachment"
              label-cols-md="4"
            >
              <v-select
                v-model="
                  patientInfo.postop_complications_TEER_complete_leaflets_detachment
                "
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Other TEER Complications"
              label-for="other_teer_complications"
              label-cols-md="4"
            >
              <v-select
                v-model="
                  patientInfo.postop_complications_TEER_complication_other
                "
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Other TEER Complication Description"
              label-for="postop_complications_TEER_complication_other_description"
              label-cols-md="4"
            >
              <b-form-input
                id="postop_complications_TEER_complication_other_description"
                v-model="
                  patientInfo.postop_complications_TEER_complication_other_description
                "
                placeholder="Other TEER Complication Description"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { dateMask } from "@core/utils/utils";
import { mapState } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        postop_complications_hemothorax: "",
        postop_complications_pericardial_tamponade: "",
        postop_complications_rethoracotomy: "",
        postop_complications_rethoracotomy_reason: "",
        postop_complications_AKIN_renal_failure: "",
        postop_complications_delir: "",
        postop_complications_new_onset_afib: "",
        postop_complications_new_conduction_disorder: "",
        postop_complications_rhythm_TAA: "",
        postop_complications_pacemaker: "",
        postop_complications_pacemaker_postop_days_to_pacemaker: "",
        postop_complications_BARC_bleeding_30d: "",
        postop_complications_tracheotomy: "",
        postop_complications_pneumonia: "",
        postop_complications_sepsis: "",
        postop_complications_impaired_wound_healing: "",
        postop_complications_stroke: "",
        postop_complications_myocardial_infarction: "",
        postop_complications_cardiac_surgery_reintervention: "",
        postop_complications_cardiac_surgery_reintervention_type: "",

        postop_complications_SAM: "",
        postop_complications_SAM_treatment: "",

        postop_complications_LCOS: "",
        postop_complications_LCOS_treatment: "",

        postop_complications_TEER_partial_leaflets_detachment: "",
        postop_complications_TEER_complete_leaflets_detachment: "",
        postop_complications_TEER_complication_other: "",
        postop_complications_TEER_complication_other_description: "",
      },
      options: ["yes", "no"],
      options1: [
        "bleeding",
        "tamponade",
        "low output",
        "wound healing disorder",
        "unstable sternum",
      ],
      options2: [
        "none",
        "Crea increase > 0,3mg/dl or 1,5-1,9x baseline",
        "Creatinine 2,0-2,9x baseline",
        "Dialysis or creatinine 3x baseline or >4mg/dl",
      ],
      options3: ["Type 0", "Type 1", "Type 2", "Type 3", "Type 4", "Type 5"],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
    stay_d() {
      return (this.patientInfo.stay_h / 24).toFixed(2);
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.postop_complications_hemothorax =
        v.postop_complications_hemothorax;
      this.patientInfo.postop_complications_pericardial_tamponade =
        v.postop_complications_pericardial_tamponade;
      this.patientInfo.postop_complications_rethoracotomy =
        v.postop_complications_rethoracotomy;
      this.patientInfo.postop_complications_rethoracotomy_reason =
        v.postop_complications_rethoracotomy_reason;
      this.patientInfo.postop_complications_AKIN_renal_failure =
        v.postop_complications_AKIN_renal_failure;
      this.patientInfo.postop_complications_delir =
        v.postop_complications_delir;
      this.patientInfo.postop_complications_new_onset_afib =
        v.postop_complications_new_onset_afib;
      this.patientInfo.postop_complications_new_conduction_disorder =
        v.postop_complications_new_conduction_disorder;
      this.patientInfo.postop_complications_rhythm_TAA =
        v.postop_complications_rhythm_TAA;
      this.patientInfo.postop_complications_pacemaker =
        v.postop_complications_pacemaker;
      this.patientInfo.postop_complications_pacemaker_postop_days_to_pacemaker =
        v.postop_complications_pacemaker_postop_days_to_pacemaker;
      this.patientInfo.postop_complications_BARC_bleeding_30d =
        v.postop_complications_BARC_bleeding_30d;
      this.patientInfo.postop_complications_tracheotomy =
        v.postop_complications_tracheotomy;
      this.patientInfo.postop_complications_pneumonia =
        v.postop_complications_pneumonia;
      this.patientInfo.postop_complications_sepsis =
        v.postop_complications_sepsis;
      this.patientInfo.postop_complications_impaired_wound_healing =
        v.postop_complications_impaired_wound_healing;
      this.patientInfo.postop_complications_stroke =
        v.postop_complications_stroke;
      this.patientInfo.postop_complications_myocardial_infarction =
        v.postop_complications_myocardial_infarction;
      this.patientInfo.postop_complications_cardiac_surgery_reintervention =
        v.postop_complications_cardiac_surgery_reintervention;
      this.patientInfo.postop_complications_cardiac_surgery_reintervention_type =
        v.postop_complications_cardiac_surgery_reintervention_type;

      this.patientInfo.postop_complications_SAM = v.postop_complications_SAM;
      this.patientInfo.postop_complications_SAM_treatment =
        v.postop_complications_SAM_treatment;

      this.patientInfo.postop_complications_LCOS = v.postop_complications_LCOS;
      this.patientInfo.postop_complications_LCOS_treatment =
        v.postop_complications_LCOS_treatment;

      this.patientInfo.postop_complications_TEER_partial_leaflets_detachment =
        v.postop_complications_TEER_partial_leaflets_detachment;
      this.patientInfo.postop_complications_TEER_complete_leaflets_detachment =
        v.postop_complications_TEER_complete_leaflets_detachment;
      this.patientInfo.postop_complications_TEER_complication_other =
        v.postop_complications_TEER_complication_other;
      this.patientInfo.postop_complications_TEER_complication_other_description =
        v.postop_complications_TEER_complication_other_description;
    },
  },
};
</script>

<style>
</style>
