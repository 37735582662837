<template>
  <b-row class="match-height postop-tab">
    <b-col cols="12">
      <echo ref="echo"></echo>
    </b-col>
    <b-col md="4">
      <icu ref="icu"></icu>
    </b-col>
    <b-col md="8">
      <complications ref="complications"></complications>
    </b-col>
    <!-- <b-col cols="6">
      <ecc ref="ecc"></ecc>
    </b-col> -->
  </b-row>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import Echo from "./postop/Echo";
import Icu from "./postop/ICU";
import Complications from "./postop/Complications";
import { mapState, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import config from "@/config";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    Echo,
    Icu,
    Complications,
  },
  timers: { updatePatient: config.TIMER_OPTION },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  methods: {
    ...mapActions("patient", ["updatePatientById"]),
    updatePatient() {
      if (this.readOnly) {
        return;
      }

      let activeElement = document.activeElement;
      let blockingElements = ["input", "textarea"];
      let isFlatPickrInput =
        activeElement && activeElement.classList.contains("flatpickr-input");

      // check whether an input field is the active elemnent,
      //if it is, do not interrupt the users entries with updating
      // the patientInfo but return instead
      if (
        activeElement &&
        blockingElements.indexOf(activeElement.tagName.toLowerCase()) !== -1 &&
        !isFlatPickrInput // still save, if the active element is a flatpickr element and thus a date field
      ) {
        return;
      }

      let patientInfo = {
        ...this.$refs.echo.patientInfo,
        ...this.$refs.icu.patientInfo,
        ...this.$refs.complications.patientInfo,
        // ...this.$refs.ecc.patientInfo,
      };

      this.updatePatientById(patientInfo).catch((err) => {
        this.showToast(
          "danger",
          "Error",
          "An error occured while updating the patient."
        );
      });
    },
    showToast(variant, title, content) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: content,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.postop-tab {
  .vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .bordered-card {
    border: 1px solid rgba(34, 41, 47, 0.125);
  }
}
</style>
