<template>
  <b-card title="ICU" sub-title="Information about ICU">
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Stay [h]"
              label-for="postop_ICU_stay_h"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.postop_ICU_stay_h"
                type="number"
                id="postop_ICU_stay_h"
                placeholder="Stay [h]"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Stay [h]" label-for="stay_h" label-cols-md="4">
              <div class="vertical-align">
                {{ stay_d }}
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Ventilation [h]"
              label-for="postop_ICU_ventilation_hours"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.postop_ICU_ventilation_hours"
                type="number"
                id="postop_ICU_ventilation_hours"
                placeholder="Ventilation [h]"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="PostOP rhythm"
              label-for="postop_ICU_postop_rhythm"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.postop_ICU_postOP_rhythm"
                label="value"
                :options="options1"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-card class="bordered-card" title="Red blood unit transfusion">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Within 48h"
                    label-for="postop_ICU_red_blood_units_transfusion_within_48h"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.postop_ICU_red_blood_units_transfusion_within_48h
                      "
                      type="number"
                      id="postop_ICU_red_blood_units_transfusion_within_48h"
                      placeholder="Within 48h"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Overall"
                    label-for="postop_ICU_red_blood_units_transfusion_overall"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.postop_ICU_red_blood_units_transfusion_overall
                      "
                      type="number"
                      id="postop_ICU_red_blood_units_transfusion_overall"
                      placeholder="Overall"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="HD support">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Impella"
                    label-for="postop_ICU_impella"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.postop_ICU_impella"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Left vs. right"
                    label-for="postop_ICU_left_vs_right"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.postop_ICU_left_vs_right"
                      label="value"
                      :options="options2"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="ECMO"
                    label-for="postop_ICU_hd_support_ECMO"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.postop_ICU_hd_support_ECMO"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="ECMO vs. ECLS"
                    label-for="postop_ICU_ECMO_vs_ECLS"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.postop_ICU_ECMO_vs_ECLS"
                      label="value"
                      :options="options3"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="NO ventilation"
                    label-for="postop_ICU_hd_support_NO_ventilation"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.postop_ICU_hd_support_NO_ventilation"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { dateMask } from "@core/utils/utils";
import { mapState } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        postop_ICU_stay_h: 0,
        postop_ICU_ventilation_hours: 0,
        postop_ICU_postOP_rhythm: "",
        postop_ICU_red_blood_units_transfusion_within_48h: 0,
        postop_ICU_red_blood_units_transfusion_overall: 0,
        postop_ICU_impella: "",
        postop_ICU_left_vs_right: "",
        postop_ICU_hd_support_ECMO: "",
        postop_ICU_ECMO_vs_ECLS: "",
        postop_ICU_hd_support_NO_ventilation: "",
      },
      options: ["yes", "no"],
      options1: [
        "sinus rhythm",
        "AV block I",
        "AV block II",
        "AV block III",
        "A fib",
        "sick sinus",
      ],
      options2: [
        "left impella cp",
        "impella 5.0",
        "impelle 5.5",
        "right impella",
      ],
      options3: ["vv ECMO", "va ECLS"],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
    stay_d() {
      return (this.patientInfo.postop_ICU_stay_h / 24).toFixed(2);
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.postop_ICU_stay_h = v.postop_ICU_stay_h;
      this.patientInfo.postop_ICU_ventilation_hours =
        v.postop_ICU_ventilation_hours;
      this.patientInfo.postop_ICU_postOP_rhythm = v.postop_ICU_postOP_rhythm;
      this.patientInfo.postop_ICU_red_blood_units_transfusion_within_48h =
        v.postop_ICU_red_blood_units_transfusion_within_48h;
      this.patientInfo.postop_ICU_red_blood_units_transfusion_overall =
        v.postop_ICU_red_blood_units_transfusion_overall;
      this.patientInfo.postop_ICU_impella = v.postop_ICU_impella;
      this.patientInfo.postop_ICU_left_vs_right = v.postop_ICU_left_vs_right;
      this.patientInfo.postop_ICU_hd_support_ECMO =
        v.postop_ICU_hd_support_ECMO;
      this.patientInfo.postop_ICU_ECMO_vs_ECLS = v.postop_ICU_ECMO_vs_ECLS;
      this.patientInfo.postop_ICU_hd_support_NO_ventilation =
        v.postop_ICU_hd_support_NO_ventilation;
    },
  },
};
</script>

<style>
</style>
